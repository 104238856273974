import React from 'react';
import { graphql } from 'gatsby';

// TODO: move to custom page?
import {
  AboutQuery,
  ContentfulAboutContent,
  ContentfulAboutContentContent,
  Maybe,
  // eslint-disable-next-line import/extensions,import/no-unresolved
} from '../../types/graphql-types';
import Layout from '../components/layout';
import SEO from '../components/seo';
import { Header1 } from '../components/headers';
import ContentfulRichText from '../components/contentfulRichText';
import SideNavMenu from '../components/menus/sideNavMenu';

type AboutContent = Pick<ContentfulAboutContent, 'sectionId' | 'header'> & {
  content?: Maybe<Pick<ContentfulAboutContentContent, 'raw'>>;
};

type SectionProps = {
  section: AboutContent;
};

const Section: React.FC<SectionProps> = ({ section }: SectionProps) => (
  <div
    className="side-nav-section -mt-8 pt-24"
    id={section.sectionId as string}
  >
    {section.header && (
      <Header1 color="black" mobileColorsFlipped className="mb-10 mx-5 md:mx-0">
        {section.header}
      </Header1>
    )}
    <div className="about-content text-base text-white md:text-black">
      <ContentfulRichText document={section.content} />
    </div>
  </div>
);

type AboutProps = {
  data: AboutQuery;
};

const About: React.FC<AboutProps> = ({ data }: AboutProps) => {
  const {
    allContentfulAboutContent: { edges },
  } = data;

  const contentSectionsById: Record<string, AboutContent> = edges.reduce(
    (acc: Record<string, AboutContent>, contentSection) => {
      acc[contentSection.node.sectionId || ''] = contentSection.node;
      return acc;
    },
    {}
  );

  const menuItems = [
    {
      id: 'what-is-usm',
      text: 'What is USM?',
    },
    {
      id: 'principles-of-unity',
      text: 'Principles of Unity',
    },
    {
      id: 'leadership-and-structure',
      text: 'Leadership and Structure',
    },
    // {
    //   id: 'regions-and-branches',
    //   text: 'Regions and Branches',
    // },
    // {
    //   id: 'history',
    //   text: 'History',
    // },
  ];

  return (
    <Layout pathname="/about">
      <SEO title="About" />
      <div className="container mx-auto md:flex md:pb-60">
        <div className="w-1/4 hidden md:block">
          <div className="fixed flex container mx-auto z-10">
            <div className="w-1/4 pt-16 pr-16">
              <SideNavMenu pageSlug="about" menuItems={menuItems} />
            </div>
            <div className="flex flex-1" />
          </div>
        </div>

        <div className="md:flex md:flex-1 z-20">
          <div className="md:max-w-screen-xs xl:max-w-screen-sm">
            {menuItems.map(({ id }) => {
              if (!contentSectionsById[id]) {
                return null;
              }
              return <Section key={id} section={contentSectionsById[id]} />;
            })}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query About {
    allContentfulAboutContent {
      edges {
        node {
          sectionId
          header
          content {
            raw
          }
        }
      }
    }
  }
`;

export default About;
